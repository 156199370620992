/* oxanium-300 - latin */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/oxanium/oxanium-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/oxanium/oxanium-v14-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/oxanium/oxanium-v14-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/oxanium/oxanium-v14-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/oxanium/oxanium-v14-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/oxanium/oxanium-v14-latin-300.svg#Oxanium')
      format('svg'); /* Legacy iOS */
}
/* oxanium-regular - latin */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/oxanium/oxanium-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/oxanium/oxanium-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/oxanium/oxanium-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/oxanium/oxanium-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/oxanium/oxanium-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/oxanium/oxanium-v14-latin-regular.svg#Oxanium')
      format('svg'); /* Legacy iOS */
}
/* oxanium-500 - latin */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/oxanium/oxanium-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/oxanium/oxanium-v14-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/oxanium/oxanium-v14-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/oxanium/oxanium-v14-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/oxanium/oxanium-v14-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/oxanium/oxanium-v14-latin-500.svg#Oxanium')
      format('svg'); /* Legacy iOS */
}
/* oxanium-600 - latin */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/oxanium/oxanium-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/oxanium/oxanium-v14-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/oxanium/oxanium-v14-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/oxanium/oxanium-v14-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/oxanium/oxanium-v14-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/oxanium/oxanium-v14-latin-600.svg#Oxanium')
      format('svg'); /* Legacy iOS */
}
/* oxanium-700 - latin */
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/oxanium/oxanium-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/oxanium/oxanium-v14-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/oxanium/oxanium-v14-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/oxanium/oxanium-v14-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/oxanium/oxanium-v14-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/oxanium/oxanium-v14-latin-700.svg#Oxanium')
      format('svg'); /* Legacy iOS */
}
